<template>
    <b-overlay :show="loading.email" rounded="sm" class="card">
        <div class="card-body d-flex flex-column justify-content-between">
            <div>
                <p class="text-center mb-4">
                    <img src="@/assets/images/tendencys.svg" class=" img-80" alt="">
                </p>
                <transition name="custom-classes-transition" mode="out-in" enter-active-class="animated faster fadeIn"
                    leave-active-class="animated faster fadeOut">
                    <div class="text-center" v-if="step == 1" key="emailMessage">
                        <h3 class=" mb-2" v-text="$t('login.login')"></h3>
                        <p class="f-w-500" v-text="$t('messages.tendnecysAccount')"></p>
                    </div>
                    <div class=" text-center" v-if="step == 2" key="welcomeMessage">
                        <h3 class="mb-2 " v-text="$t('general.welcome')"></h3>
                        <p>
                            <b-button variant="light" pill @click="fnReturn()">
                                <span v-text="user.email"></span>
                                <i class="fa fa-chevron-circle-down ml-2"></i>
                            </b-button>
                        </p>
                    </div>
                </transition>
            </div>

            <v-observer ref="formLogin" tag="form" @submit.prevent="fnValidateLogin()">
                <transition name="custom-classes-transition" mode="out-in" enter-active-class="animated faster fadeIn"
                    leave-active-class="animated faster fadeOut">
                    <!-- EMAIL -->
                    <div v-if="step == 1" key="email">
                        <b-alert variant="success" :show="recoveryPassword" dismissible>
                            <p class="m-0" v-text="$t('messages.passwordRecoveredSuccessfully')"></p>
                        </b-alert>
                        <b-alert variant="success" :show="emailConfirmation" dismissible>
                            <p class="m-0" v-text="$t('messages.emailVerify')"></p>
                        </b-alert>
                        <v-validation rules="required|email" v-slot="{ validated,passed,errors }"
                            :name="$t('login.email')">
                            <b-form-group :label="$t('login.email')" label-for="input-email" class="mb-1">
                                <b-input-group>
                                    <template #prepend>
                                        <b-input-group-text class=" bg-light"> <i class="fa fa-at"></i>
                                        </b-input-group-text>
                                    </template>
                                    <b-form-input v-model="user.email" type="email" @input="fnResetErrors()"
                                        :state=" (validated) ? passed : null " id="userEmail" placeholder="Email">
                                    </b-form-input>
                                </b-input-group>
                                <b-form-invalid-feedback :state="(validated) ? passed : null" v-text="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </v-validation>
                    </div>
                    <div v-if="step == 2" key="password">
                        <!-- PASSWORD -->
                        <v-validation rules="required|min:8" v-slot="{ validated,passed,errors }"
                            :name="$t('login.password')" v-show="validatedEmail">
                            <b-form-group :label="$t('login.password')" label-for="input-password" class="mb-1">
                                <b-input-group class="">
                                    <template #prepend>
                                        <b-input-group-text class=" bg-light"> <i class="fa fa-key"></i>
                                        </b-input-group-text>
                                    </template>
                                    <b-form-input v-model="user.password" @input="fnResetErrors()"
                                        :type=" seePass ? 'text' : 'password' " :state=" (validated) ? passed : null "
                                        id="userPassword" placeholder="*******">
                                    </b-form-input>
                                </b-input-group>
                                <b-form-invalid-feedback :state="(validated) ? passed : null" v-text="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </v-validation>
                        <b-form-checkbox id="seePass" v-model="seePass" name="seePass" :value="true"
                            :unchecked-value="false">
                            <span v-text="$t('messages.showPassword')"></span>
                        </b-form-checkbox>
                        <b-alert fade :show="loginErrors.emailPass" variant="danger" class="mt-2">
                            <p class="m-0" v-text="$t('login.alertPassword')"></p>
                        </b-alert>
                        <div class="row justify-content-center mt-4">
                            <div class="col-auto">
                                <vue-recaptcha class="w-100" ref="recaptchaComponent" size="invisible"
                                    @verify="fnOnVerify" @expired="fnOnExpired" :sitekey="CONST.CAPTCHACODE"
                                    :loadRecaptchaScript="true">
                                </vue-recaptcha>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="mt-2">
                    <b-alert fade :show="loginErrors.account" variant="danger">
                        <p class="m-0" v-text="$t('messages.accountNotExist')"></p>
                    </b-alert>
                    <div v-if="emailConfirmationMessage">
                        <b-alert fade show variant="success">
                            <p class="m-0" v-text="$t('messages.confirmationEmailSent')"></p>
                        </b-alert>
                    </div>
                    <div v-else>
                        <b-alert fade :show="loginErrors.emailConfirmation" variant="danger">
                            <p class="m-0" v-text="$t('messages.emailNotConfimated')"></p>
                        </b-alert>
                        <b-button variant="light animated fadeIn w-100" v-if="loginErrors.emailConfirmation"
                            @click="fnApiResendConfirmation()" :disabled="loading.resendEmail">
                            <div v-if="loading.resendEmail">
                                <i class="fa fa-spinner fa-spin"></i>
                            </div>
                            <div v-else>
                                <span v-text="$t('messages.resendConfirmation')"></span>
                            </div>
                        </b-button>
                    </div>
                </div>
            </v-observer>

            <transition name="custom-classes-transition" mode="out-in" enter-active-class="animated faster fadeIn"
                leave-active-class="animated faster fadeOut">
                <div class="bg-light p-4 d-none d-md-block" v-if="step == 1" key="tendencysMessage">
                    <p>
                        <span v-html="$t('messages.tendencysMessage') + ' '"></span>
                        <router-link to="/accounts/info" v-text="$t('general.button.moreInfo') + '.' "></router-link>
                    </p>
                </div>
            </transition>

            <div class="row">
                <div class="col-12 col-md-6 mb-2 mb-md-0 ">
                    <transition name="custom-classes-transition" mode="out-in"
                        enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
                        <b-button variant="link" class="w-100" v-text="$t('general.signup')" v-if="step == 1"
                            key="accountBtn" :to="{ path: '/accounts/signup', query: { ...$route.query }}">
                        </b-button>
                        <b-button variant="link" type="button" class="w-100"
                            @click=" userData.email = user.email ;  changeModal( {recoveryPassword : true} )"
                            v-text="$t('login.forgotPass')" v-if="step == 2" key="passBtn">
                        </b-button>
                    </transition>
                </div>

                <div class="col-12 col-md-6 ml-auto">
                    <transition name="custom-classes-transition" mode="out-in"
                        enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
                        <b-button variant="primary" class="w-100" @click="fnValidateLogin()" v-if="step == 1"
                            key="validate1" :disabled="loginErrors.account || loading.email ||  loading.password ">
                            <div v-if="loading.email ||  loading.password">
                                <i class="fa fa-spinner fa-spin"></i>
                            </div>
                            <div v-else>
                                <span v-text="$t('general.login')"></span>
                            </div>
                        </b-button>
                        <b-button variant="primary" class="w-100" @click="fnValidateLogin()" v-if="step == 2"
                            key="validate2" :disabled="loginErrors.account || loading.email ||  loading.password ">
                            <div v-if="loading.email ||  loading.password">
                                <i class="fa fa-spinner fa-spin"></i>
                            </div>
                            <div v-else>
                                <span v-text="$t('general.login')"></span>
                            </div>
                        </b-button>
                    </transition>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';
    import {
        mapMutations,
        mapState
    } from 'vuex';
    export default {
        components: {
            VueRecaptcha,
        },
        data() {
            return {
                user: {
                    email: null,
                    password: null,
                    siteId: null,
                    to: null,
                },
                emailConfirmation: false,
                emailConfirmationMessage: false,
                validatedEmail: false,
                loginErrors: {
                    emailPass: false,
                    account: false,
                    emailConfirmation: false,
                },
                loading: {
                    email: false,
                    password: false,
                    resendEmail: false,
                },
                step: 1,
                seePass: false,
                recoveryPassword: false,
            }
        },
        computed: {
            ...mapState('AccountLogin', ['userData']),
        },
        methods: {
            ...mapMutations('AccountLogin', ['changeModal']),
            fnReturn() {
                this.step = 1;
                this.user.email = null;
                this.user.password = null;
                setTimeout(() => {
                    document.getElementById('userEmail').focus();
                }, 800);
            },
            fnResetErrors() {
                this.loginErrors = {
                    emailPass: false,
                    account: false,
                    emailConfirmation: false,
                }
            },
            async fnValidateLogin() {
                if (await this.$refs.formLogin.validate()) {
                    if (this.step == 1) {
                        this.fnResetErrors();
                        this.fnValidateEmail();
                    }
                    if (this.step == 2) {
                        this.loading.email = true;
                        this.$refs.recaptchaComponent.reset();
                        this.$refs.recaptchaComponent.execute();
                    }
                }
            },

            async fnValidateEmail() {
                if (this.user.email) {
                    this.loading.email = true;
                    this.validatedEmail = false;
                    this.loginErrors.account = false;
                    this.loginErrors.emailConfirmation = false;
                    await axiosAccount.get(`validate/email/${this.user.email}`).then(async response => {
                        setTimeout(() => {
                            if (response.data.status == 1) {
                                this.validatedEmail = false;
                                this.loginErrors.account = true;
                            } else {
                                this.step = 2;
                                this.validatedEmail = true;
                                setTimeout(() => {
                                    document.getElementById('userPassword').focus();
                                }, 800);
                            }
                        }, 500);
                    }).catch(error => {});
                    setTimeout(() => {
                        this.loading.email = false;
                    }, 300);
                }
            },

            // Recaptcha
            async fnOnVerify(response) {
                await axiosAccount.post("../accounts/login", {
                    email: this.user.email,
                    password: this.user.password,
                    site_id: this.user.siteId,
                    to: this.user.to,
                    token: response,
                }).then(async response => {
                    if (response.data.redirect == 'phone') {
                        localStorage.setItem('accountUrl', response.data.url );
                        localStorage.setItem('accountEmail', response.data.email );
                         this.$router.push(`/accounts/phone-verification?email=${this.user.email}&site_id=${this.user.siteId}`);
                    } else {
                        window.location.replace(response.data.url);
                    }
                }).catch(error => {
                    console.log(error);
                    if (error.response.status == 401) {
                        this.loginErrors.emailPass = true;
                    }
                    if (error.response.status == 403) {
                        this.loginErrors.emailConfirmation = true;
                        this.emailConfirmationMessage = false;
                    }
                });
                this.loading.email = false;
            },
            fnOnExpired: function () {
                console.log('Expired');
            },
            fnResetRecaptcha() {
                this.$refs.recaptcha.reset() // Direct call reset method
            },
            async fnApiResendConfirmation() {
                this.loading.resendEmail = true;
                await axiosAccount.post("/register/confirmation/resend", {
                    email: this.user.email,
                    site_id: this.user.siteId,
                }).then(async response => {
                    this.emailConfirmationMessage = true;
                }).catch(error => {
                    console.log(error);
                });
                this.loading.resendEmail = false;
            }
        },
        beforeMount() {
            this.fnReturn();
            if (this.$route.query.site_id) {
                this.user.siteId = this.$route.query.site_id;
            } else {
                this.$router.push('/accounts');
            }
            if (this.$route.query.email) {
                console.log(this.$route.query);
                this.user.email = this.$route.query.email;
                if (!this.$route.query.recovery_password) {
                    setTimeout(() => {
                        this.fnValidateLogin();
                    }, 800);
                } else {
                    this.recoveryPassword = true;
                }
            }
            if (this.$route.query.to) {
                this.user.to = this.$route.query.to;
            }
            if (this.$route.query.confirmation) {
                this.emailConfirmation = true;
            }
        },
    }
</script>